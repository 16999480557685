import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppService } from './app.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent implements OnInit {
  isLoading: boolean;

  constructor(private appService: AppService, private router: Router) { }

  ngOnInit(): void {
    const url = window.location.href;
    const token = localStorage.getItem('AUTH');
    if (!url.includes('/verify') && token) {
      this.isLoading = true;
      this.appService.checkStatus().subscribe({
        next: (data) => {
          const [protocol, empty, domain, ...remainUrl] = url.split('/');
          if (!data.success) {
            this.router.navigate(['/login']);
          } else if (data.success && ( remainUrl.includes('login') || remainUrl.includes(''))) {
            this.router.navigate(['/home']);
          } else if (data.success && remainUrl.length > 0) {
            this.router.navigate([`/${remainUrl.join('/')}`]);
          }
        },
        error: (e) => {
          this.isLoading = false;
        }
      });
    }
  }
}
