import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { AppService } from '../app.service';
import { CompanyModel } from './company.model';

@Component({
  selector: 'app-company',
  templateUrl: './company.component.html',
  styleUrls: ['./company.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CompanyComponent implements OnInit {

  @Input() companyData: CompanyModel[];

  companyControl: FormControl

  constructor(
    private router: Router,
    private appService: AppService
  ) { }

  ngOnInit(): void {
    this.companyControl = new FormControl('')
  }

  onCompanySelect(companyId: string) {
    // localStorage.removeItem('companyId');
    this.appService.sendSelectedCompany(companyId).subscribe(data => {
      if (data.success) {
        localStorage.setItem('AUTH', data.token);
        localStorage.setItem('companyId', companyId);
        this.appService.setUserPermissions();
        this.router.navigate(['/home']);
      }
    });
  }

  trackByCompanyId(index: number, company: CompanyModel) {
    return company.companyId;
  }

}
