<div class="flex justify-center" *ngIf="!showCode">
  <form nz-form [formGroup]="form" class="login-form">
    <nz-form-item>
      <nz-form-control [nzValidateStatus]="formControls.email">
        <input nzSize="large" nz-input [formControl]="formControls.email" placeholder="Email *"
          class="font-semibold text-center h-custom-input text-15" />
      </nz-form-control>
      <div *ngIf="!isLogin" class="relative flex items-center">
        <span nzTooltipTitle="Go To Login" nzTooltipPlacement="right" nz-tooltip
          class="text-3xl cursor-pointer material-icons-two-tone absolute leading-none ml-2"
          (click)="onGotoLogin()">{{cancelIcon}}</span>
      </div>
    </nz-form-item>
    <nz-form-item *ngIf="isLogin">
      <nz-form-control [nzValidateStatus]="formControls.password">
        <input nzSize="large" nz-input type="password" [formControl]="formControls.password" placeholder="Password *"
          class="font-semibold text-center h-custom-input text-15" />
      </nz-form-control>
      <div *ngIf="isLogin" class="relative flex items-center">
        <span nzTooltipTitle="Forgot Password?" nzTooltipPlacement="right" nz-tooltip
          class="text-3xl cursor-pointer material-icons-two-tone absolute leading-none ml-2"
          (click)="onForgot()">{{questionIcon}}</span>
      </div>
    </nz-form-item>
    <div *ngIf="isLogin" class="flex justify-center items-center flex-col mb-3">
      <span class="pb-4 text-rejected" *ngIf="errorMessage">{{errorMessage}}</span>
      <div>
        <!-- <span>Don't have account ?</span> -->
        <span class="pl-2 cursor-pointer font-semibold new-account" (click)="onSignUp()">New Account Signup</span>
      </div>
    </div>
  </form>
</div>
<div class="flex justify-center" *ngIf="!showCode">
  <nz-spin nzSimple [nzSpinning]="isSubmitting" nzTip="Please wait..."></nz-spin>
  <button *ngIf="!isSubmitting" nz-button class="bg-inherit border-0" [disabled]="!form.valid"
    (click)="submitFormData()">
    <span class="text-6xl cursor-pointer material-icons-two-tone leading-none save-icon"
      [ngClass]="{'selected': form.valid}">{{submitIcon}}</span>
  </button>
</div>
<div class="flex justify-center" *ngIf="showCode">
  <form nz-form [formGroup]="codeForm" class="login-form">
    <nz-form-item>
      <nz-form-control [nzValidateStatus]="codeFormControls.code">
        <input nzSize="large" nz-input [formControl]="codeFormControls.code" placeholder="Code *"
          class="font-semibold text-center h-custom-input text-15" type="code" name="code"/>
      </nz-form-control>
    </nz-form-item>
  </form>
</div>
<div class="flex justify-center" *ngIf="showCode">
  <nz-spin nzSimple [nzSpinning]="isSubmitting" nzTip="Please wait..."></nz-spin>
  <button *ngIf="!isSubmitting" nz-button class="bg-inherit border-0" [disabled]="!codeForm.valid"
    (click)="verifyCode()">
    <span class="text-6xl cursor-pointer material-icons-two-tone leading-none save-icon"
      [ngClass]="{'selected': codeForm.valid}">{{submitIcon}}</span>
  </button>
</div>
